import React, {useState} from "react";
import UserName from "../../components/username/UserName";
import UserTickets from "../../components/user-tickets/UserTickets";
import UserKeys from "../../components/user-keys/UserKeys";
import { ReactComponent as Arw } from "../../img/ar.svg";
import { ReactComponent as YouTube } from "../../img/List Item/Left/Items card/YouTube.svg";
import { ReactComponent as Tg } from "../../img/List Item/Left/Items card/Telegram.svg";
import { ReactComponent as Twitter } from "../../img/List Item/Left/Items card/Twitter.svg";
import { ReactComponent as Coin } from "../../img/coin.svg";
import { ReactComponent as Coin1 } from "../../img/coin-1.svg";
import { ReactComponent as Pluss } from "../../img/Actions/Plus.svg";
import { ReactComponent as Block } from "../../img/Block.svg";
import { ReactComponent as Ref } from "../../img/Nav bar/Icon-1.svg";
import li from "../../img/Bg light top.png";
import { ReactComponent as Key } from "../../img/Key.svg";
import { ReactComponent as Jetton } from "../../img/Jetton.svg";
import { ReactComponent as X } from "../../img/x.svg";
import "./tasks.css";

const Tasks = ({onHandleNavbar}) => {
  const [showKey, setShowKey] = useState(false);
  const [showTickets, setShowTickets] = useState(false);
  const [goBack, setGoBack] = useState(false);
  return (
    <>
        {showTickets && (
        <>
          <div
            className="black-zone"
            style={goBack ? { display: "none" } : null}
            onClick={() => {
              setGoBack(true);
              setTimeout(() => {
                setShowTickets(false);
                onHandleNavbar();
                setGoBack(false);
              }, "600");
            }}
          ></div>
          <div
            className="window"
            style={
              goBack
                ? {
                    maxHeight: "280px",
                    animation: "window_back 0.6s",
                    bottom: "-300px",
                  }
                : { maxHeight: "280px" }
            }
          >
            <div className="window_data">
              <img src={li} width={"100%"} alt="" />
              <div style={{ padding: "16px", height: "100%" }}>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <X
                  className="close_x"
                    style={{ opacity: "12%" }}
                    onClick={() => {
                      setGoBack(true);
                      setTimeout(() => {
                        setShowTickets(false);
                        onHandleNavbar();
                        setGoBack(false);
                      }, "600");
                    }}
                  />
                </div>
                <div
                  style={{
                    position: "relative",
                    height: "100%",
                    textAlign: "center",
                  }}
                >
                  <div>
                    <Jetton />
                    <p className="wnd_header">Game pass</p>
                    <span className="wnd_text">
                      You can invite friends through your referral link and get
                      bonuses
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {showKey && (
        <>
          <div
            style={goBack ? { display: "none" } : null}
            className="black-zone"
            onClick={() => {
              setGoBack(true);
              setTimeout(() => {
                setShowKey(false);
                onHandleNavbar();
                setGoBack(false);
              }, "600");
            }}
          ></div>
          <div
            className="window"
            style={
              goBack
                ? {
                    maxHeight: "280px",
                    animation: "window_back 0.6s",
                    bottom: "-300px",
                  }
                : { maxHeight: "280px" }
            }
          >
            <div className="window_data">
              <img src={li} width={"100%"} alt="" />
              <div style={{ padding: "16px", height: "100%" }}>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <X
                    className="close_x"
                    style={{ opacity: "12%" }}
                    onClick={() => {
                      setGoBack(true);
                      setTimeout(() => {
                        setShowKey(false);
                        onHandleNavbar();
                        setGoBack(false);
                      }, "600");
                    }}
                  />
                </div>
                <div
                  style={{
                    position: "relative",
                    height: "100%",
                    textAlign: "center",
                  }}
                >
                  <div>
                    <Key />
                    <p className="wnd_header">Box Key</p>
                    <span className="wnd_text">
                      You can invite friends through your referral link and get
                      bonuses
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    <div className="pg">
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <UserName />
        <div style={{ display: "flex", gap: "6px" }}>
          <UserTickets onTickets={() => {
                    if (!goBack) {
                      setShowTickets(true);
                      onHandleNavbar();
                    }
                  }}/>
          <UserKeys onShowKey={() => {
                    if (!goBack) {
                      setShowKey(true);
                      onHandleNavbar();
                    }
                  }}/>
        </div>
      </div>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div className="ts_texts">
          <div className="tss">
            <Coin />
            <Pluss />
            <Coin1 />
          </div>
          <p>Earn more coins and keys</p>
          <span>
            Complete tasks from the list to earn coins and keys. Keep an eye on
            the section, new tasks will appear here
          </span>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <p className="pg_header_s">Tasks</p>
        <p style={{ fontSize: "15px", color: "#FFFFFF66" }}>7</p>
      </div>
      <div className="items_pg">
        <div className="play">
          <div>
            <div className="fw">
              <YouTube />
            </div>
            <div className="flw_texts">
              <p>Follow Nise YouTube</p>
              <span>1,000 NISE</span>
            </div>
          </div>
          <Arw />
        </div>
        <div className="play">
          <div>
            <div className="fw">
              <Tg />
            </div>
            <div className="flw_texts">
              <p>Subscribe Nise</p>
              <span>1,000 NISE + 1 BOX KEY</span>
            </div>
          </div>
          <Arw />
        </div>
        <div className="play">
          <div>
            <div className="fw">
              <Tg />
            </div>
            <div className="flw_texts">
              <p>Subscribe Daily Assest</p>
              <span>1,000 NISE + 1 BOX KEY</span>
            </div>
          </div>
          <Arw />
        </div>
        <div className="play">
          <div>
            <div className="fw">
              <Tg />
            </div>
            <div className="flw_texts">
              <p>Subscribe Daily Assest Ru</p>
              <span>1,000 NISE + 1 BOX KEY</span>
            </div>
          </div>
          <Arw />
        </div>
        <div className="play">
          <div>
            <div className="fw">
              <Twitter />
            </div>
            <div className="flw_texts">
              <p>Follow Nise Twitter</p>
              <span>1,000 NISE</span>
            </div>
          </div>
          <Arw />
        </div>
        <div>
          <div
            className="start_play"
            style={{
              borderBottomLeftRadius: "0px",
              borderBottomRightRadius: "0px",
            }}
          >
            <div>
              <div className="fw">
                <Ref />
              </div>
              <div className="flw_texts">
                <p>Invite 5 friends</p>
                <span>1,000 NISE</span>
              </div>
            </div>
            <Arw />
          </div>
          <div className="the_lines">
            <div className="gr_line">
              <div className="wh_line" style={{width: "0px"}}></div>
            </div>
            <span>0 of 5</span>
          </div>
        </div>
        <div
          className="play"
          style={{
            position: "relative",
            overflow: "hidden",
            border: "2px solid var(--border-primary, #FFFFFF0F)"
          }}
        >
          <div className="blured"></div>
          <div className="bl_texts" style={{gap: '8px'}}>
            <Block />
            <p>Invite 10 friends to unlock</p>
          </div>
          <div>
            <div className="fw">
              <Twitter />
            </div>
            <div className="flw_texts">
              <p>Follow Nise Twitter</p>
              <span>1,000 NISE</span>
            </div>
          </div>
          <Arw />
        </div>
      </div>
    </div>
    </>
  );
};

export default Tasks;
