import React from "react";
import "./user-name.css";
import { ReactComponent as Rang } from "../../img/Airdrop_rank.svg";
import { useSelector } from "react-redux";

const UserName = () => {
  const name = useSelector(state => state.currentUser.name)

  return (
    <div className="user_name_">
      <Rang />
      <p>{name}</p>
    </div>
  );
};

export default UserName;
