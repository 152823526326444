import React, { useState } from "react";
import "./shop.css";
import UserName from "../../components/username/UserName";
import UserTickets from "../../components/user-tickets/UserTickets";
import UserKeys from "../../components/user-keys/UserKeys";
import Total from "../../components/total/Total";
import Items from "../../components/items/Items";
import Upgrades from "../../components/upgrades/Upgrades";
import li from "../../img/Bg light top.png";
import { ReactComponent as Key } from "../../img/Key.svg";
import { ReactComponent as Jetton } from "../../img/Jetton.svg";
import { ReactComponent as X } from "../../img/x.svg";
import { ReactComponent as Plus } from "../../img/plustime.svg";

const Shop = ({ onHandleNavbar }) => {
  const [openUpgrade1, setOpenUpgrade1] = useState(false);
  const [openUpgrade2, setOpenUpgrade2] = useState(false);
  const [openUpgrade3, setOpenUpgrade3] = useState(false);
  const [showKey, setShowKey] = useState(false);
  const [showTickets, setShowTickets] = useState(false);
  const [goBack, setGoBack] = useState(false);
  return (
    <>
    {showTickets && (
        <>
          <div
            className="black-zone"
            style={goBack ? { display: "none" } : null}
            onClick={() => {
              setGoBack(true);
              setTimeout(() => {
                setShowTickets(false);
                onHandleNavbar();
                setGoBack(false);
              }, "600");
            }}
          ></div>
          <div
            className="window"
            style={
              goBack
                ? {
                    maxHeight: "280px",
                    animation: "window_back 0.6s",
                    bottom: "-300px",
                  }
                : { maxHeight: "280px" }
            }
          >
            <div className="window_data">
              <img src={li} width={"100%"} alt="" />
              <div style={{ padding: "16px", height: "100%" }}>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <X
                  className="close_x"
                    style={{ opacity: "12%" }}
                    onClick={() => {
                      setGoBack(true);
                      setTimeout(() => {
                        setShowTickets(false);
                        onHandleNavbar();
                        setGoBack(false);
                      }, "600");
                    }}
                  />
                </div>
                <div
                  style={{
                    position: "relative",
                    height: "100%",
                    textAlign: "center",
                  }}
                >
                  <div>
                    <Jetton />
                    <p className="wnd_header">Game pass</p>
                    <span className="wnd_text">
                      You can invite friends through your referral link and get
                      bonuses
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {showKey && (
        <>
          <div
            style={goBack ? { display: "none" } : null}
            className="black-zone"
            onClick={() => {
              setGoBack(true);
              setTimeout(() => {
                setShowKey(false);
                onHandleNavbar();
                setGoBack(false);
              }, "600");
            }}
          ></div>
          <div
            className="window"
            style={
              goBack
                ? {
                    maxHeight: "280px",
                    animation: "window_back 0.6s",
                    bottom: "-300px",
                  }
                : { maxHeight: "280px" }
            }
          >
            <div className="window_data">
              <img src={li} width={"100%"} alt="" />
              <div style={{ padding: "16px", height: "100%" }}>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <X
                  className="close_x"
                    style={{ opacity: "12%" }}
                    onClick={() => {
                      setGoBack(true);
                      setTimeout(() => {
                        setShowKey(false);
                        onHandleNavbar();
                        setGoBack(false);
                      }, "600");
                    }}
                  />
                </div>
                <div
                  style={{
                    position: "relative",
                    height: "100%",
                    textAlign: "center",
                  }}
                >
                  <div>
                    <Key />
                    <p className="wnd_header">Box Key</p>
                    <span className="wnd_text">
                      You can invite friends through your referral link and get
                      bonuses
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      <div className="pg">
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <UserName />
          <div style={{ display: "flex", gap: "6px" }}>
            <UserTickets onTickets={() => {
                    if (!goBack) {
                      setShowTickets(true);
                      onHandleNavbar();
                    }
                  }}/>
            <UserKeys onShowKey={() => {
                    if (!goBack) {
                      setShowKey(true);
                      onHandleNavbar();
                    }
                  }}/>
          </div>
        </div>
        <Total />
        <p className="pg_header_s">Items</p>
        <Items />
        <p className="pg_header_s" style={{ marginTop: "20px" }}>
          Upgrades
        </p>
        <Upgrades
          open1={() => {
            if (!goBack) {
              setOpenUpgrade1(true);
              onHandleNavbar();
            }
          }}
          open2={() => {
            if (!goBack) {
              setOpenUpgrade2(true);
              onHandleNavbar();
            }
          }}
          open3={() => {
            if (!goBack) {
              setOpenUpgrade3(true);
              onHandleNavbar();
            }
          }}
        />
      </div>
      {openUpgrade1 && (
        <>
          <div
            className="black-zone"
            onClick={() => {
              setGoBack(true);
              setTimeout(() => {
                setOpenUpgrade1(false);
                onHandleNavbar();
                setGoBack(false);
              }, "500");
            }}
            style={goBack ? { display: "none" } : null}
          ></div>
          <div
            className="window"
            style={
              goBack
                ? {
                    maxHeight: "335px",
                    animation: "window_back 0.6s",
                    bottom: "-300px",
                  }
                : { maxHeight: "335px" }
            }
          >
            <div className="window_data">
              <img src={li} width={"100%"} alt="" />
              <div style={{ padding: "16px", height: "100%" }}>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <X
                  className="close_x"
                    style={{ opacity: "12%" }}
                    onClick={() => {
                      setGoBack(true);
                      setTimeout(() => {
                        setOpenUpgrade1(false);
                        onHandleNavbar();
                        setGoBack(false);
                      }, "500");
                    }}
                  />
                </div>
                <div
                  style={{
                    position: "relative",
                    height: "100%",
                    textAlign: "center",
                  }}
                >
                  <div>
                    <Plus />
                    <p className="wnd_header">+2 seconds to the game</p>
                    <p className="wnd_text">
                      Your game time will increase by two seconds after
                      purchasing this upgrade.
                    </p>
                  </div>
                  <button className="by">Buy</button>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {openUpgrade2 && (
        <>
          <div
            className="black-zone"
            onClick={() => {
              setGoBack(true);
              setTimeout(() => {
                setOpenUpgrade2(false);
                onHandleNavbar();
                setGoBack(false);
              }, "500");
            }}
            style={goBack ? { display: "none" } : null}
          ></div>
          <div
            className="window"
            style={
              goBack
                ? {
                    maxHeight: "280px",
                    animation: "window_back 0.6s",
                    bottom: "-300px",
                  }
                : { maxHeight: "280px" }
            }
          >
            <div className="window_data">
              <img src={li} width={"100%"} alt="" />
              <div style={{ padding: "16px", height: "100%" }}>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <X
                  className="close_x"
                    style={{ opacity: "12%" }}
                    onClick={() => {
                      setGoBack(true);
                      setTimeout(() => {
                        setOpenUpgrade2(false);
                        onHandleNavbar();
                        setGoBack(false);
                      }, "500");
                    }}
                  />
                </div>
                <div
                  style={{
                    position: "relative",
                    height: "100%",
                    textAlign: "center",
                  }}
                >
                  <div>
                    <Plus />
                    <p className="wnd_header">+2 seconds to play</p>
                    <span className="wnd_text">
                      You can invite friends through your referral link and get
                      bonuses
                    </span>
                  </div>
                  <button className="by">Buy</button>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {openUpgrade3 && (
        <>
          <div
            className="black-zone"
            onClick={() => {
              setGoBack(true);
              setTimeout(() => {
                setOpenUpgrade3(false);
                onHandleNavbar();
                setGoBack(false);
              }, "500");
            }}
            style={goBack ? { display: "none" } : null}
          ></div>
          <div
            className="window"
            style={
              goBack
                ? {
                    maxHeight: "280px",
                    animation: "window_back 0.6s",
                    bottom: "-300px",
                  }
                : { maxHeight: "280px" }
            }
          >
            <div className="window_data">
              <img src={li} width={"100%"} alt="" />
              <div style={{ padding: "16px", height: "100%" }}>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <X
                  className="close_x"
                    style={{ opacity: "12%" }}
                    onClick={() => {
                      setGoBack(true);
                      setTimeout(() => {
                        setOpenUpgrade3(false);
                        onHandleNavbar();
                        setGoBack(false);
                      }, "500");
                    }}
                  />
                </div>
                <div
                  style={{
                    position: "relative",
                    height: "100%",
                    textAlign: "center",
                  }}
                >
                  <div>
                    <Plus />
                    <p className="wnd_header">+2 seconds to play</p>
                    <span className="wnd_text">
                      You can invite friends through your referral link and get
                      bonuses
                    </span>
                  </div>
                  <button className="by">Buy</button>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Shop;
