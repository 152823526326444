import React from "react";
import "./game-end.css";
import { ReactComponent as Logo } from "../../img/ll.svg";
import { ReactComponent as Flower } from "../../img/bud.svg";

const GameEnd = ({ balance }) => {
  return (
    <div className="game_end">
      <div>
        <Logo />
        <p>Very good!</p>
        <span className="end_text">
          You have collected a good number of petals, and for that you are
          rewarded in the form of
        </span>
        <div className="end_balance">
          <span>+{balance} NISE</span>
        </div>
      </div>
    </div>
  );
};

export default GameEnd;
