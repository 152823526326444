import { React, useState, useRef } from "react";
import { useEffect } from "react";
import "./game.css";
import { ReactComponent as Bag } from "../../img/bag.svg";
import { ReactComponent as Bag1 } from "../../img/bag_1.svg";
import { ReactComponent as Bag2 } from "../../img/bag_2.svg";
import { ReactComponent as Bag3 } from "../../img/bag_3.svg";
import { ReactComponent as Bud } from "../../img/bud.svg";
import { ReactComponent as Clock } from "../../img/Clock.svg";
import { ReactComponent as LogoMark } from "../../img/LogoMark.svg";
import { useNavigate } from "react-router-dom";
import GameEnd from "../../components/game-end/GameEnd";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

const Game = ({onOpenGame}) => {
  const dispatch = useDispatch();
  const passes = useSelector(state => state.currentUser.passes)
  const navigate = useNavigate();

  useEffect(() => {
    if(passes === 0) {
      navigate('/')
    }else {
      dispatch({
        type: "change-passes",
        payload: {
          passes: passes - 1
        }
      })
    }
  }, [])

  useEffect(() => {
    const tg = window.Telegram.WebApp;
    tg.BackButton.show();
    tg.BackButton.onClick(() => {
      tg.BackButton.hide();
      navigate("/");
    });
    return () => {
      tg.BackButton.hide();
    };
  }, []);
  const [seconds, setSeconds] = useState(30);
  const [position, setPosition] = useState({
    top: window.innerHeight - 70,
    left: 0,
  });
  const [isDragging, setIsDragging] = useState(false);
  const draggableRef = useRef(null);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [screenHeight, setScreenHeight] = useState(window.innerHeight);
  const [flowers, setFlowers] = useState([]);
  const [balance, setBalance] = useState(0);

  const handleTouchStart = (e) => {
    setIsDragging(true);
  };
  const handleTouchEnd = () => {
    setIsDragging(false);
  };
  const handleTouchMove = (e) => {
    if (isDragging) {
      const touch = e.touches[0];
      setPosition({
        left: touch.clientX - 40,
        top: touch.clientY - 70,
      });
    }
  };

  const handleMouseDown = (e) => {
    setIsDragging(true);
    const shiftX =
      e.clientX - draggableRef.current.getBoundingClientRect().left;
    const shiftY = e.clientY - draggableRef.current.getBoundingClientRect().top;

    const moveAt = (pageX, pageY) => {
      setPosition({
        left: pageX - shiftX,
        top: pageY - shiftY,
      });
    };

    const onMouseMove = (event) => {
      moveAt(event.pageX, event.pageY);
    };

    document.addEventListener("mousemove", onMouseMove);

    document.addEventListener(
      "mouseup",
      () => {
        document.removeEventListener("mousemove", onMouseMove);
        setIsDragging(false);
        setPosition((prevPosition) => ({
          left: prevPosition.left,
          top: screenHeight - 70,
        }));
      },
      { once: true }
    );
  };
  
  // useEffect(() => {
  //   if (seconds > 0) {
  //     const interval = setInterval(() => {
  //       setSeconds((prevSeconds) => prevSeconds - 1);
  //     }, 1000);
  //     return () => clearInterval(interval); // Clear interval on component unmount
  //   }
  // }, [seconds]);

  // const interval = setInterval(() => {
  //   setFlowers([
  //     ...flowers, 
  //     {
  //       id: Math.random(),
  //       top:  -10,
  //       left: 0,
  //       transform:  Math.round(Math.random() * (125 - (-125)) + (-125))
  //     }])
  //     return clearInterval(interval)
  // }, 90)
  // useEffect(() => {
  //   onOpenGame(false)
  // }, [])

  return (
    <div className="game">
      {seconds <= 0 ? (
        <GameEnd balance={balance} />
      ) : ( 
        <>
        <div className="li" style={{zIndex: '99999999'}}></div>
          <div className="gm_pn">
            <div>
              <Clock />
              <span>00:{seconds < 10 && '0'}{seconds}</span>
            </div>
            <div>
              <LogoMark />
              <span>{balance}</span>
            </div>
          </div>
          <div className="game_panel">
            {flowers.map((flower) => (
              <Bud
                key={flower.id}
                className="cn"
                style={{
                  left: `${flower.left}px`,
                  top: `${flower.top}px`,
                  transform: `rotate(${flower.transform}deg)`,
                }}
              />
            ))}
            <div
              className="bag"
              ref={draggableRef}
              onMouseDown={handleMouseDown}
              onTouchStart={handleTouchStart}
              onTouchMove={handleTouchMove}
              onTouchEnd={handleTouchEnd}
              style={{
               width: "100px", height: "70px" ,
                position: "absolute",
                cursor: isDragging ? "grabbing" : "grab",
                top:
                !isDragging ? position.top =  screenHeight - 80:
                  +position.top < 50
                    ? (position.top = 50)
                    : position.top >= screenHeight - 80
                    ? (position.top = screenHeight - 80)
                    : position.top,
                left:
                  +position.left <= 0
                    ? 0
                    : +position.left < +screenWidth - 100
                    ? position.left
                    : +screenWidth - 100,
                transition: isDragging ? "all ease" : "all 1s ease",
              }}
            >
              {balance == 0 ? (
                <Bag style={{ width: "100px", height: "70px" }} />
              ) : balance == 1 ? (
                <Bag1 style={{ width: "100px", height: "70px" }} />
              ) : balance == 2 ? (
                <Bag2 style={{ width: "100px", height: "70px" }} />
              ) : (
                <Bag3 style={{ width: "100px", height: "70px" }} />
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Game;
