import React, { useEffect, useState } from "react";
import "./home.css";
import UserData from "../../components/user-data/UserData";
import UserBalance from "../../components/user-balance/UserBalance";
import UserTickets from "../../components/user-tickets/UserTickets";
import UserKeys from "../../components/user-keys/UserKeys";
import Box from "../../components/box/Box";
import StartFarm from "../../components/start-farm/StartFarm";
import { ReactComponent as X } from "../../img/x.svg";
import { ReactComponent as Key } from "../../img/Key.svg";
import { ReactComponent as Jetton } from "../../img/Jetton.svg";
import { ReactComponent as Chev } from "../../img/Actions/Chevron left.svg";
import {ReactComponent as Rank5} from "../../img/rank-5-1.svg"
import li from "../../img/Bg light top.png";
import liBg from "../../img/li-bg-top.png"
import UAParser from "ua-parser-js";
import { useSelector } from "react-redux";

const Home = ({ onHandleNavbar, onHandleTickets, onHandleKeys, error }) => {
  const rank = useSelector(state => state.currentUser.rank)
  const [ranks, setRanks] = useState( [
    { id: 1, name: 'Jonokami' },
    { id: 2, name: 'Chonin' },
    { id: 3, name: 'Jonzin' },
    { id: 4, name: 'Keninin' },
    { id: 5, name: 'Kanzaki' },
    { id: 6, name: 'Choseinin' },
    { id: 7, name: 'Kusa' },
    { id: 8, name: 'Suigyonin' },
    { id: 9, name: 'Kitanin' },
    { id: 10, name: 'Kuchinami' },
    { id: 11, name: 'Ujonin' },
    { id: 12, name: 'Kagenin' },
    { id: 13, name: 'Nagarenin' },
    { id: 14, name: 'Yokainin' },
    { id: 15, name: 'Seron' },
    { id: 16, name: 'Bakenanin' },
    { id: 17, name: 'Rainin' },
    { id: 18, name: 'Shinobiwuno' },
    { id: 19, name: 'Mizunin' },
    { id: 20, name: 'Tenin' },
    { id: 21, name: 'Irukanin' },
    { id: 22, name: 'Sorunin' },
    { id: 23, name: 'Yoginin' },
    { id: 24, name: 'Kuronin' },
    { id: 25, name: 'Moronin' },
    { id: 26, name: 'Kakinenin' },
    { id: 27, name: 'Hiyonin' },
    { id: 28, name: 'Satsuzonin' },
    { id: 29, name: 'Narentin' },
    { id: 30, name: 'Meinin' }
  ])
  
  const [rankPosition, setRankPosition] = useState(0)
  const [showKey, setShowKey] = useState(false);
  const [showTickets, setShowTickets] = useState(false);
  const [showRank, setShowRank] = useState(false)
  const [goBack, setGoBack] = useState(false);
  const parser = new UAParser();
  const result = parser.getResult();

  const margin =
    result.os.name == "iOS"
      ? { marginBottom: "95px", marginLeft: "0px", marginRight: "0px" }
      : { marginBottom: "64px", marginLeft: "0px", marginRight: "0px" };

  return (
    <>
          {showRank && (
        <>
          <div
            className="black-zone"
            style={goBack ? { display: "none" } : null}
            onClick={() => {
              setGoBack(true);
              setTimeout(() => {
                setShowRank(false);
                onHandleNavbar();
                setGoBack(false);
              }, "900");
            }}
          ></div>
          <div
            className="window"
            style={
              goBack
                ? {
                    maxHeight: "400px",
                    animation: "window_back_rank 0.9s",
                    bottom: "-600px",
                  }
                : { maxHeight: "400px" }
            }
          >
            <div className="window_data">
              <img src={liBg} width={"100%"} alt="" />
              <div style={{ padding: "16px", height: "100%" }}>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <X
                  className="close_x"
                    style={{ opacity: "12%" }}
                    onClick={() => {
                      setGoBack(true);
                      setTimeout(() => {
                        setShowRank(false);
                        onHandleNavbar();
                        setGoBack(false);
                      }, "600");
                    }}
                  />
                </div>
                <div className="ranks-slider">
                  <div style={{cursor: 'pointer', zIndex: '9999999'}} onClick={() => {
                    if(rankPosition != 0){
                      setRankPosition(rankPosition + 113)
                    }
                  }}><Chev /></div>
                  <div className="rr_">
                    <div style={{left: `${rankPosition}px`}}>
                    {
                      ranks.map(el => {
                        return (
                          <div className="rank_panels">
                            <Rank5 />
                            <p>{el.name}</p>
                            <span>{el.id === 1 ? 'Your airdrop rank' : 'Airdrop rank'}</span>
                          </div>
                        )
                      })
                    }
                    </div>
                  </div>
                  <div style={{cursor: 'pointer'}} onClick={() => {
                    if(-3277 != rankPosition) {
                      setRankPosition(rankPosition - 113)
                    }
                  }}> <Chev style={{transform: 'rotate(180deg)'}}/></div>
                </div>
              <div className="rank-window-text">
                <p>How do you raise your status?</p>
                <span>To increase your status, you need to open chests from which the status falls. Chests can be opened with the help of keys, which can be purchased in the store for coins obtained in the game by collecting flowers</span>
              </div>
              </div>
            </div>
          </div>
        </>
      )}
      {showTickets && (
        <>
          <div
            className="black-zone"
            style={goBack ? { display: "none" } : null}
            onClick={() => {
              setGoBack(true);
              setTimeout(() => {
                setShowTickets(false);
                onHandleNavbar();
                setGoBack(false);
              }, "600");
            }}
          ></div>
          <div
            className="window"
            style={
              goBack
                ? {
                    maxHeight: "280px",
                    animation: "window_back 0.6s",
                    bottom: "-300px",
                  }
                : { maxHeight: "280px" }
            }
          >
            <div className="window_data">
              <img src={li} width={"100%"} alt="" />
              <div style={{ padding: "16px", height: "100%" }}>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <X
                  className="close_x"
                    style={{ opacity: "12%" }}
                    onClick={() => {
                      setGoBack(true);
                      setTimeout(() => {
                        setShowTickets(false);
                        onHandleNavbar();
                        setGoBack(false);
                      }, "600");
                    }}
                  />
                </div>
                <div
                  style={{
                    position: "relative",
                    height: "100%",
                    textAlign: "center",
                  }}
                >
                  <div>
                    <Jetton />
                    <p className="wnd_header">Game pass</p>
                    <span className="wnd_text">
                      You can invite friends through your referral link and get
                      bonuses
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {showKey && (
        <>
          <div
            style={goBack ? { display: "none" } : null}
            className="black-zone"
            onClick={() => {
              setGoBack(true);
              setTimeout(() => {
                setShowKey(false);
                onHandleNavbar();
                setGoBack(false);
              }, "600");
            }}
          ></div>
          <div
            className="window"
            style={
              goBack
                ? {
                    maxHeight: "280px",
                    animation: "window_back 0.6s",
                    bottom: "-300px",
                  }
                : { maxHeight: "280px" }
            }
          >
            <div className="window_data">
              <img src={li} width={"100%"} alt="" />
              <div style={{ padding: "16px", height: "100%" }}>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <X
                  className="close_x"
                    style={{ opacity: "12%" }}
                    onClick={() => {
                      setGoBack(true);
                      setTimeout(() => {
                        setShowKey(false);
                        onHandleNavbar();
                        setGoBack(false);
                      }, "600");
                    }}
                  />
                </div>
                <div
                  style={{
                    position: "relative",
                    height: "100%",
                    textAlign: "center",
                  }}
                >
                  <div>
                    <Key />
                    <p className="wnd_header">Box Key</p>
                    <span className="wnd_text">
                      You can invite friends through your referral link and get
                      bonuses
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      <div className="pg hm" style={margin}>
        <div className="home_pg">
          <div>
            <UserData onHandleShowRank={() => setShowRank(true)}/>
            <div
              style={{
                display: "flex",
                marginTop: "10px",
                justifyContent: "space-between",
              }}
            >
              <UserBalance />
              <div style={{ display: "flex", gap: "6px" }}>
                <UserTickets
                  onTickets={() => {
                    if (!goBack) {
                      setShowTickets(true);
                      onHandleNavbar();
                    }
                  }}
                />
                <UserKeys
                  onShowKey={() => {
                    if (!goBack) {
                      setShowKey(true);
                      onHandleNavbar();
                    }
                  }}
                />
              </div>
            </div>
          </div>
          <Box
            onShowError={() => {
              if (!error) {
                onHandleKeys(true);
                setTimeout(() => {
                  onHandleKeys(false);
                }, "3000");
              }
            }}
          />
          <StartFarm
            onShowError={() => {
              if (!error) {
                onHandleTickets(true);
                setTimeout(() => {
                  onHandleTickets(false);
                }, "3000");
              }
            }}
          />
        </div>
      </div>
    </>
  );
};

export default Home;
