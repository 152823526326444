import React from "react";
import "./total.css";
import { ReactComponent as Coin } from "../../img/Logo_mark_total.svg";
import { useSelector } from "react-redux";

const Total = () => {
  const balance = useSelector(state => state.currentUser.balance)

  return (
    <div className="total">
      <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
        <p>{balance}</p>
        <Coin />
      </div>
      <span>Total balance</span>
    </div>
  );
};

export default Total;
