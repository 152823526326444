import React from "react";
import "./start-farm.css";
import { ReactComponent as Flw } from "../../img/img-game.svg";
import { ReactComponent as Play } from "../../img/Play.svg";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const StartFarm = ({onShowError}) => {
  const passes = useSelector(state => state.currentUser.passes);
  const navigate = useNavigate();

  const handlePlayClick = () => {
    if (passes === 0) {
      onShowError(); 
    } else {
      navigate('/game'); 
    }
  };

  return (
    <div className="start_farm">
      <div className="start_play">
        <div>
          <div className="fw">
            <Flw />
          </div>
          <div className="flw_texts">
            <p>Flower picker</p>
            <span>Cost of playing 1 pass</span>
          </div>  
        </div>
          <button className="play_button" onClick={handlePlayClick}>
            <Play />
            Play
          </button>
      </div>
      <button>Start farm</button>
    </div>
  );
};

export default StartFarm;
