import React from "react";
import "./loading.css";
import { ReactComponent as Loader } from "../../img/Logo_loader.svg";

const Loading = () => {
  return (
    <div className="loading">
      <div>
        <Loader style={{marginBottom: "30px"}}/>
      </div>
    </div>
  );
};

export default Loading;
