import React from "react";
import "./items.css";
import { ReactComponent as Key } from "../../img/List Item/Left/Items card/Key.svg";
import { ReactComponent as Tick } from "../../img/List Item/Left/Items card/Jetton.svg";
import { ReactComponent as Arw } from "../../img/ar.svg";

const Items = () => {
  return (
    <div className="items_pg" style={{paddingBottom: '0px'}}>
      <div className="play">
        <div>
          <div className="fw">
            <Key />
          </div>
          <div className="flw_texts">
            <p>Buy 1 box key</p>
            <span>The cost is 600 $NISE</span>
          </div>
        </div>
        <Arw />
      </div>
      <div className="play">
        <div>
          <div className="fw">
            <Tick />
          </div>
          <div className="flw_texts">
            <p>Add 1 pass per farm</p>
            <span>The cost is 50 $NISE</span>
          </div>
        </div>
        <Arw />
      </div>
    </div>
  );
};

export default Items;
