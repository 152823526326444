import React from "react";
import { ReactComponent as Time } from "../../img/List Item/Left/Items card/Plus time-1.svg";
import { ReactComponent as TimeMinus } from "../../img/List Item/Left/Items card/Minus time.svg";
import { ReactComponent as X2 } from "../../img/List Item/Left/Items card/x2.svg";
import { ReactComponent as Arw } from "../../img/ar.svg";

const Upgrades = ({ open1, open2, open3 }) => {
  return (
    <div className="items_pg">
      <div className="play" onClick={open1}>
        <div>
          <div className="fw">
            <Time />
          </div>
          <div className="flw_texts">
            <p>Add 2 seconds to the game</p>
            <span>The cost is 600 $NISE</span>
          </div>
        </div>
        <Arw />
      </div>
      <div className="play" onClick={open2}>
        <div>
          <div className="fw">
            <TimeMinus />
          </div>
          <div className="flw_texts">
            <p>Remove 30 minutes per farm</p>
            <span>The cost is 5,000 $NISE</span>
          </div>
        </div>
        <Arw />
      </div>
      <div className="play" onClick={open3}>
        <div>
          <div className="fw">
            <Time />
          </div>
          <div className="flw_texts">
            <p>+2 seconds to play</p>
            <span>The cost is 600 $NISE</span>
          </div>
        </div>
        <Arw />
      </div>
      <div className="play">
        <div>
          <div className="fw">
            <X2 />
          </div>
          <div className="flw_texts">
            <p>x2 to win </p>
            <span>The cost is 2,500 $NISE</span>
          </div>
        </div>
        <Arw />
      </div>
    </div>
  );
};

export default Upgrades;
