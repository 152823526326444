import {createStore} from "redux";

const store = createStore((state, action) => {
    switch(action.type) {
        case "edit-current-user":
            return {
                ...state,
                currentUser: {
                    passes: action.payload.passes,
                    keys: action.payload.keys,
                    balance: action.payload.balance,
                    name: action.payload.name,
                    rank: action.payload.rank,
                    rankType: action.payload.rankType
                }
            }
            break
        case "change-keys":
            return {
                ...state,
                currentUser: {
                    ...state.currentUser,
                    keys: action.payload.keys
                }
            }
            break
        case "change-passes":
            return {
                ...state,
                currentUser: {
                    ...state.currentUser,
                    passes: action.payload.passes
                }
            }
            break
        case "change-balance":
            return {
                ...state,
                currentUser: {
                    ...state.currentUser,
                    balance: action.payload.balance
                }
            }
    }

    return state;
}, {
    currentUser: {
        name: "",
        passes: 0,
        keys: 0,
        balance: 0,
        rank: 1,
        rankType: 1
    }
})

export default store