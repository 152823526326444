import React from "react";
import "./box.css";
import {ReactComponent as Boxx} from "../../img/boxx.svg";
import li from "../../img/light.png";

const Box = ({onShowError}) => {
  return (
    <div className="box">
      <div className="box-bg">
        <Boxx />
      </div>
      <button onClick={onShowError}>
        Open box <img src={li} />
      </button>
    </div>
  );
};

export default Box;
