import React from 'react'
import './user-keys.css'
import { ReactComponent as Coin } from "../../img/Items card/Icon-1.svg";
import { useSelector } from "react-redux";

const UserKeys = ({onShowKey}) => {
  const keys = useSelector(state => state.currentUser.keys)

  return (
    <div className="user-keys" onClick={onShowKey}>
      <Coin />
      <span>{keys}</span>
    </div>
  )
}

export default UserKeys