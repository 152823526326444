import React from "react";
import "./user-data.css";
import el from "../../img/Ellipse 62.png";
import { ReactComponent as Rang } from "../../img/rang1.svg";
import { ReactComponent as Arrow } from "../../img/Icon.svg";
import { useSelector } from "react-redux";

const UserData = ({onHandleShowRank}) => {

  const name = useSelector(state => state.currentUser.name)

  return (
    <div className="user-data" onClick={onHandleShowRank}>
      <img className="ellipse" src={el} alt="" />
      <div className="uu">
        <div className="rang">
          <Rang />
        </div>
        <div>
          <p className="user_name">{name}</p>
          <p className="user_status">Jonokami</p>
        </div>
      </div>
      <Arrow />
    </div>
  );
};

export default UserData;
