import React from "react";
import el from "../../img/Ellipse 62.png";
import { ReactComponent as Rang } from "../../img/rang1.svg";
import { ReactComponent as Arrow } from "../../img/Icon.svg";

const Friend = () => {
  return (
    <div className="user-data" style={{
        background: "var(--bg-secondary, #ffffff0f)"
    }}>
      <img className="ellipse" src={el} alt="" />
      <div className="uu">
        <div className="rang">
          <Rang />
        </div>
        <div>
          <p className="user_name">Username</p>
          <p className="user_status">1,667 NISE</p>
        </div>
      </div>
      <div className="prft">
        <p>+500 NISE</p>
        <p>Profit</p>
      </div>
    </div>
  );
};

export default Friend;
